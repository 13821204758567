// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
    background-color: #fff;
    color: #1e1e1e;
    padding: 20px 160px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: row; /* Safari support */
    position: sticky;
    top: 72px; /* Adjust based on the height of the Navbar */
    z-index: 1000;
}
.back-button-icon{
    font-size: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/BackButton/Backbutton.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,aAAa;IACb,mBAAmB,EACO,mBAAmB;IAC7C,gBAAgB;IAChB,SAAS,EAAE,6CAA6C;IACxD,aAAa;AACjB;AACA;IACI,eAAe;AACnB","sourcesContent":[".back-button {\n    background-color: #fff;\n    color: #1e1e1e;\n    padding: 20px 160px;\n    font-size: 16px;\n    cursor: pointer;\n    display: flex;\n    flex-direction: row;\n    position: -webkit-sticky; /* Safari support */\n    position: sticky;\n    top: 72px; /* Adjust based on the height of the Navbar */\n    z-index: 1000;\n}\n.back-button-icon{\n    font-size: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
