import Thailand from '../../Assets/Thailand.jpg';
import Abudhabi from '../../Assets/Abudhabi.jpg';
import Australia1 from '../../Assets/Australia1.jpg';

export const packageslist=[
    {
        id:1,
        packagetittle:"Phuket Pattaya Packages",
        packagesubtittle:"Discover ancient ruins and relaxing beach resorts in Thailand",
        packagedescription:'We bdhavghdfahdgafsgdfashgdfashgdfashgdfafsghdfhgsafddgashfdhgasfdgfasjgdvhasgcdghascgdfsadjfahdfutawfwdvashgfhgdcdcasjfdtasfsdashfduyafsvdhasfdyhdvhvdghfcashgfjgdshgfjmsjbdfggsdhfjh wish to travel to different locations when we want to escape our mundane lifestyle. What if there is a stunning location that can provide you with the relaxing',
        Images:[Thailand, Abudhabi, Australia1, Thailand, Thailand, Thailand, Thailand, Thailand, Thailand],
    }
]