import React from "react";
import Navbar from "../Components/Navbar/Navbar1";
import './Listing.css';
import Packagelistingimages from "../Components/Packagelistingimages/Packagelistingimages";
import Itinerarytittle from "../Components/Itinerarytittle/Itinerarytittle";
import ItineraryTabs from "../Components/Itinerarytabs/Itinerarytabs";
import { packages } from "../Components/Packagelisting/Packagelisting";

export default function Packageitinerary() {
    return (
        <div className="packagelisting">
            <Navbar />
            <Itinerarytittle />
            {packages.map((packageItem) => (
                <Packagelistingimages packageImages={packageItem.Images} key={packageItem.id} />
            ))}
            <ItineraryTabs />
        </div>
    );
}
