// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .packagefilter{
    margin: 20px 160px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}
  
  .filter-container {
    position: relative;
    height: auto;
    flex: 0 0 300px; /* Set a fixed width for the filter */
  }
  
  .filter-container .filter-sidebar {
    width: 100%;
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow-y: auto; /* Scroll within the filter if it exceeds the viewport height */
  }
  
  .listing-container {
    width: 100%;
    flex: 1 1;
    overflow-y: auto;
    max-height: 100vh; /* Limit the height to viewport */
  }
  
  /* Add custom scrollbar styling if needed */
  .listing-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .listing-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  
  .listing-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Filterpackagelisting/Filterpackagelisting.css"],"names":[],"mappings":";EACE;IACE,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;EAEE;IACE,kBAAkB;IAClB,YAAY;IACZ,eAAe,EAAE,qCAAqC;EACxD;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,MAAM;IACN,iBAAiB;IACjB,gBAAgB,EAAE,+DAA+D;EACnF;;EAEA;IACE,WAAW;IACX,SAAO;IACP,gBAAgB;IAChB,iBAAiB,EAAE,iCAAiC;EACtD;;EAEA,2CAA2C;EAC3C;IACE,UAAU;EACZ;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["\n  .packagefilter{\n    margin: 20px 160px;\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n}\n  \n  .filter-container {\n    position: relative;\n    height: auto;\n    flex: 0 0 300px; /* Set a fixed width for the filter */\n  }\n  \n  .filter-container .filter-sidebar {\n    width: 100%;\n    position: sticky;\n    top: 0;\n    max-height: 100vh;\n    overflow-y: auto; /* Scroll within the filter if it exceeds the viewport height */\n  }\n  \n  .listing-container {\n    width: 100%;\n    flex: 1;\n    overflow-y: auto;\n    max-height: 100vh; /* Limit the height to viewport */\n  }\n  \n  /* Add custom scrollbar styling if needed */\n  .listing-container::-webkit-scrollbar {\n    width: 8px;\n  }\n  \n  .listing-container::-webkit-scrollbar-thumb {\n    background-color: #ccc;\n    border-radius: 4px;\n  }\n  \n  .listing-container::-webkit-scrollbar-track {\n    background: #f1f1f1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
