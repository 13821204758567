import React from 'react';
import './Budgetsection.css'; // External CSS file for styling
import { FaStar } from 'react-icons/fa';

const budgetOptions = [
  { amount: '₹50,000', color: '#4CAF50' }, // Green
  { amount: '₹75,000', color: '#2196F3' }, // Blue
  { amount: '₹100,000', color: '#9C27B0' }, // Purple
  { amount: '₹200,000', color: '#FF9800' }, // Orange
  { amount: '₹300,000', color: '#673AB7' }, // Violet
];

const BudgetSection = () => {
  return (
    <div className="budget-section">
      <h2 className="budget-heading">
        Holidays For Every <span className="highlight">Budget</span>
      </h2>
      <div className="budget-box-container">
        {budgetOptions.map((option, index) => (
          <div
            key={index}
            className="budget-box"
            style={{ backgroundColor: option.color }}
          >
            <div className="budget-label">Below</div>
            <div className="budget-amount">{option.amount}</div>
            <FaStar className="budget-icon" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BudgetSection;
