import React from 'react';
import RatingStars from '../Rating/RatingStars.jsx';
import { Secondary } from '../Button/Button.jsx';
import { hotelData } from './hotelData.js';

export default function HotelListing1({ onSelectHotel }) {
    return (
        <div className="Activity-listing">
            {hotelData.map((hotel) => ( 
                <div key={hotel.Hotelid} className='Hotel-content-information'>
                    <div className='Hotel-content'>
                        <div className='Hotel-Image-Badge'>
                            <img src={hotel.Hotelimage} alt={hotel.Hotelname} />
                            {hotel.Hotelexcluded && (
                                <div className="wt-recommended-badge">
                                    <span>WT RECOMMENDED</span>
                                </div>
                            )}
                        </div>
                        <div className='activity-details'>
                            <p className='Date'>{hotel.HotelDate}</p>
                            <h3>{hotel.Hotelname}</h3>
                            <p className="Hotel-Rating">
                                <RatingStars rating={hotel.Hotelrating} /> Trip Advisor
                            </p>
                            <ul className='activity-time-type-amount'>
                                <li>{hotel.Hotelaccomodates}</li>
                                <li>{hotel.Hotelamount}</li>
                                <li>{hotel.Hotelarea}</li>
                                <li>{hotel.Hoteltype}</li>
                            </ul>
                            <ul className="Inclusions">
                                Inclusions:
                                {hotel.Hotelinclusions?.map((Inclusion, index) => (
                                    <li key={index}>{Inclusion}</li>
                                ))}
                            </ul>
                            <ul className="Exclusions">
                                Exclusions:
                                {hotel.Hotelexclusions?.map((Exclusion, index) => (
                                    <li key={index}>{Exclusion}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="insurance-actions">
                            <Secondary buttontext="Select" onClick={() => onSelectHotel(hotel)} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
