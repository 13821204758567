import React, { useState } from 'react';
import { FaFileAlt, FaCreditCard, FaLock, FaTimesCircle, FaSearch, FaSyncAlt } from 'react-icons/fa';
import './ItineraryPrice.css';
import Buttonwithicon from '../../Button/ButtonwithIcon.jsx';
import CancellationPolicy from './CancellationPolicy.jsx';
import TermsConditions from './TermsConditions.jsx';
import PaymentPolicy from './PaymentPolicy.jsx';
import PrivacyPolicy from './PrivacyPolicy.jsx';
import Amendment from './Amendment.jsx';
import ScopeofService from './ScopeofService.jsx';

const Packagepolicies = () => {
  const [ShowCacellationPolicy, setShowCacellationPolicy] = useState(false); // State to control popup visibility
  const [ShowTermsConditions, setShowTermsConditions] = useState(false);
  const [ShowPaymentPolicy, setShowPaymentPolicy] = useState(false);
  const [ShowPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [ShowScopeofService, setShowScopeofService] = useState(false);
  const [ShowAmendment, setShowAmendment] = useState(false);
  const handleTerms = () => {
    console.log('Open Terms when clicked');
    setShowTermsConditions(true);
  };

  const handlePaymentPolicy = () => {
    console.log('Open Payment Policy when clicked');
    setShowPaymentPolicy(true);
  };

  const handlePrivacyPolicy = () => {
    console.log('Open Privacy Policy when clicked');
    setShowPrivacyPolicy(true);
  };

  const handleCancellationPolicy = () => {
    setShowCacellationPolicy(true); // Show the popup when "Cancellation Policy" is clicked
  };

  const handleScope = () => {
    console.log('Open Scope when clicked');
    setShowScopeofService(true);
  };

  const handleAmendment = () => {
    console.log('Open Amendment when clicked');
    setShowAmendment(true);
  };

  const closeCacellationPolicy = () => {
    setShowCacellationPolicy(false); // Close the popup
    setShowTermsConditions(false);
    setShowPaymentPolicy(false);
    setShowPrivacyPolicy(false);
    setShowScopeofService(false);
    setShowAmendment(false);
  };

  return (
    <div className="Buttonwithicon">
      <div>
      <Buttonwithicon 
        icon={<FaFileAlt />}  
        buttontext='Terms & Conditions' 
        className='texted-button1' 
        onClick={handleTerms} 
      />
      <hr className="separator" />

      <Buttonwithicon 
        icon={<FaCreditCard />}  
        buttontext='Payment Policy' 
        className='texted-button1' 
        onClick={handlePaymentPolicy} 
      />
      <hr className="separator" />

      <Buttonwithicon 
        icon={<FaLock />}  
        buttontext='Privacy Policy' 
        className='texted-button1' 
        onClick={handlePrivacyPolicy} 
      />
      <hr className="separator" />

      <Buttonwithicon 
        icon={<FaTimesCircle />}  
        buttontext='Cancellation Policy' 
        className='texted-button1' 
        onClick={handleCancellationPolicy} 
      />
      <hr className="separator" />

      <Buttonwithicon 
        icon={<FaSearch />}  
        buttontext='Our Scope of Service' 
        className='texted-button1' 
        onClick={handleScope} 
      />
      <hr className="separator" />

      <Buttonwithicon 
        icon={<FaSyncAlt />}  
        buttontext='Amendment of Booking of Guest' 
        className='texted-button1' 
        onClick={handleAmendment} 
      />

      {/* Render the CancellationPopup when showCancellationPopup is true */}
      {ShowCacellationPolicy && (
        <CancellationPolicy onClose={closeCacellationPolicy} />
      )}
      {ShowTermsConditions && (
        <TermsConditions onClose={closeCacellationPolicy} />
      )}
      {ShowPaymentPolicy && (
        <PaymentPolicy onClose={closeCacellationPolicy} />
      )}
      {ShowPrivacyPolicy && (
        <PrivacyPolicy onClose={closeCacellationPolicy} />
      )}
      {ShowScopeofService && (
        <ScopeofService onClose={closeCacellationPolicy} />
      )}
      {ShowAmendment && (
        <Amendment onClose={closeCacellationPolicy} />
      )}
      </div>
    
    </div>
  );
};

export default Packagepolicies;
