// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-sidebar{
    background-color: white;
    width: 20%;
    height: fit-content;
    padding: 16px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    position: sticky;
    top: 92px; /* Sticks to the top of the page while scrolling */
}
.filter-sidebar h2{
    margin: 0px;
}
.filter-header{
border: none;
height: auto;
display: flex;
flex-direction: column;
gap: 10px;
}
.filter-header h4{
    margin: 0%;
    color: #888888;
}
.clearfilter{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.textedbutton {
    background: none;
    border: none;
    color: #d32f2f; /* Choose any color you like */
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px; /* Adjust size as needed */
  }
  
  .textedbutton:hover {
    color: #d32f2f; /* Darken on hover */
  }`, "",{"version":3,"sources":["webpack://./src/Components/Listingfilter/filter.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,SAAS,EAAE,kDAAkD;AACjE;AACA;IACI,WAAW;AACf;AACA;AACA,YAAY;AACZ,YAAY;AACZ,aAAa;AACb,sBAAsB;AACtB,SAAS;AACT;AACA;IACI,UAAU;IACV,cAAc;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,cAAc,EAAE,8BAA8B;IAC9C,eAAe;IACf,0BAA0B;IAC1B,eAAe,EAAE,0BAA0B;EAC7C;;EAEA;IACE,cAAc,EAAE,oBAAoB;EACtC","sourcesContent":[".filter-sidebar{\n    background-color: white;\n    width: 20%;\n    height: fit-content;\n    padding: 16px;\n    gap: 10px;\n    display: flex;\n    flex-direction: column;\n    border-radius: 8px;\n    position: sticky;\n    top: 92px; /* Sticks to the top of the page while scrolling */\n}\n.filter-sidebar h2{\n    margin: 0px;\n}\n.filter-header{\nborder: none;\nheight: auto;\ndisplay: flex;\nflex-direction: column;\ngap: 10px;\n}\n.filter-header h4{\n    margin: 0%;\n    color: #888888;\n}\n.clearfilter{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.textedbutton {\n    background: none;\n    border: none;\n    color: #d32f2f; /* Choose any color you like */\n    cursor: pointer;\n    text-decoration: underline;\n    font-size: 16px; /* Adjust size as needed */\n  }\n  \n  .textedbutton:hover {\n    color: #d32f2f; /* Darken on hover */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
