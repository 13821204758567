import Primarybutton from "../Button/Primary";
import './Packagetittle.css';
import { packageslist } from "../Packagelisting/Packages.js";

export default function Packagetittle(){
    return(
        <div>
            {packageslist.map((packageItem) => (
        <div className="packagetittle">
            <div className="packagetittleh3">
            <h3>{packageItem.packagetittle}</h3>
            <p>{packageItem.packagesubtittle}</p>
            </div>
        <Primarybutton button='Request Call back' />
        </div>
        ))}
        </div>
    );
}