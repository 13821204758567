// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.packagetittle{
    width: 100%;
    background-color: white;
    padding: 10px 160px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 72px; /* Sticks to the top of the page while scrolling */
    z-index: 500;
}
.packagetittleh3{
    width: 85%;
}
.packagetittleh3 h3{
    margin: 10px 0px;
}
.packagetittleh3 p{
    margin: 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Packagetittle/Packagetittle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,SAAS,EAAE,kDAAkD;IAC7D,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".packagetittle{\n    width: 100%;\n    background-color: white;\n    padding: 10px 160px;\n    margin: 0px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    position: sticky;\n    top: 72px; /* Sticks to the top of the page while scrolling */\n    z-index: 500;\n}\n.packagetittleh3{\n    width: 85%;\n}\n.packagetittleh3 h3{\n    margin: 10px 0px;\n}\n.packagetittleh3 p{\n    margin: 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
