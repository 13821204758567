// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hotel-content-ul{
    margin: 0%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.Hotel-Inclusioncontent-ul{
    margin: 0%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #C7FEB3;
    padding: 20px 20px 20px 40px;
    border-radius: 8px;
}
.Hotel-Exclusioncontent-ul{
    margin: 0%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #FEE7E7;
    padding: 20px 20px 20px 40px;
    border-radius: 8px;
}
.Hotel-Inclusion-Exclusion{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.Hotel-Inclusion{
    width: 50%;
}
.Hotel-Exclusion{
    width: 50%;
}
.Hotel-Overview{
    line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Hotel/HotelContent.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,yBAAyB;IACzB,4BAA4B;IAC5B,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,yBAAyB;IACzB,4BAA4B;IAC5B,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".Hotel-content-ul{\n    margin: 0%;\n    padding-left: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.Hotel-Inclusioncontent-ul{\n    margin: 0%;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    background-color: #C7FEB3;\n    padding: 20px 20px 20px 40px;\n    border-radius: 8px;\n}\n.Hotel-Exclusioncontent-ul{\n    margin: 0%;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    background-color: #FEE7E7;\n    padding: 20px 20px 20px 40px;\n    border-radius: 8px;\n}\n.Hotel-Inclusion-Exclusion{\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    gap: 16px;\n}\n.Hotel-Inclusion{\n    width: 50%;\n}\n.Hotel-Exclusion{\n    width: 50%;\n}\n.Hotel-Overview{\n    line-height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
