import BackButton from "../Components/BackButton/BackButton";
import Filteractivitylisting from "../Components/Filterpackagelisting/FilterActivityListing";
import Navbar from "../Components/Navbar/Navbar1";

export default function ActivityListing(){
    return(
        <div style={{backgroundColor:"#F7F8FB"}}>
            <Navbar />
            <BackButton />
            <Filteractivitylisting />
        </div>
    )
}