import ReadMoreLess from "../Button/ReadmoreReadless.jsx";
import './Listingtittledescription.css';
import { packageslist } from "../Packagelisting/Packages.js";
export default function Listingtittledescription(){
    return(
        <div>
            {packageslist.map((packageItem) => (
        <div className="listingtittledescription">
        <h3 className="listingtittle">{packageItem.packagetittle}</h3>
        <ReadMoreLess text={packageItem.packagedescription} />
        </div>
        ))}
        </div>
    );
}