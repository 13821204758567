// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Itinerarytittle{
    background-color: white;
    padding: 20px 160px;
    margin: 0%;
    top: 72px; /* Adjust based on the height of the Navbar */
    z-index: 1000; /* Safari support */
    position: sticky;
}`, "",{"version":3,"sources":["webpack://./src/Components/Itinerarytittle/Itinerarytittle.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,SAAS,EAAE,6CAA6C;IACxD,aAAa,EACa,mBAAmB;IAC7C,gBAAgB;AACpB","sourcesContent":[".Itinerarytittle{\n    background-color: white;\n    padding: 20px 160px;\n    margin: 0%;\n    top: 72px; /* Adjust based on the height of the Navbar */\n    z-index: 1000;\n    position: -webkit-sticky; /* Safari support */\n    position: sticky;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
