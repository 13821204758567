import { useParams } from "react-router-dom"; // Import the useParams hook
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import './Itinerarytittle.css';
export default function Itinerarytittle(){
    const { id } = useParams();
    // Find the package by id
  const packageData = packages.find(pkg => pkg.id === parseInt(id));

  if (!packageData) {
    return <h1>Package not found</h1>;
  }
    return(
        <h2 className="Itinerarytittle">{packageData.title}</h2>
    )
}