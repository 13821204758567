import React, { useState } from 'react';
import { FaCircleUser } from "react-icons/fa6";
import './testimonials.css';
import Image from '../../Assets/Australia1.jpg';
import Imageslider from '../Imageslider/Imageslider';
// import { Testimonialrating } from '../Rating/Rating';
import Primarybutton from '../Button/Primary';
import RatingStars from '../Rating/RatingStars';

// Sample review data
export const reviewData = [
  {
    id: 1,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 1,
    reviewedon: '2 mins ago',
    customername: 'Courtney Henry',
    images: [Image, Image, Image,Image, Image, Image,Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 2,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 2,
    reviewedon: 'Yesterday',
    customername: 'Jane Doe',
    images: [Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 3,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 3,
    reviewedon: '04-11-2024',
    customername: 'John Smith',
    images: [Image, Image, Image],
    Message: 'Randomly generated text for demo purposes.'
  },
  {
    id: 4,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 4,
    reviewedon: '04-11-2024',
    customername: 'Sarah Connor',
    images: [Image, Image, Image],
    Message: 'Generated text that mimics the structure of real writing.'
  },

  {
    id: 5,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 5,
    reviewedon: '04-11-2024',
    customername: 'Courtney Henry',
    images: [Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 6,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 1,
    reviewedon: '04-11-2024',
    customername: 'Jane Doe',
    images: [Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 7,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 2,
    reviewedon: '04-11-2024',
    customername: 'John Smith',
    images: [Image, Image, Image],
    Message: 'Randomly generated text for demo purposes.'
  },
  {
    id: 8,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 3,
    reviewedon: '04-11-2024',
    customername: 'Sarah Connor',
    images: [Image, Image, Image],
    Message: 'Generated text that mimics the structure of real writing.'
  },
  {
    id: 9,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 4,
    reviewedon: '04-11-2024',
    customername: 'Courtney Henry',
    images: [Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 10,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 5,
    reviewedon: '04-11-2024',
    customername: 'Jane Doe',
    images: [Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 11,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 1,
    reviewedon: '04-11-2024',
    customername: 'John Smith',
    images: [Image, Image, Image],
    Message: 'Randomly generated text for demo purposes.'
  },
  {
    id: 12,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 2,
    reviewedon: '04-11-2024',
    customername: 'Sarah Connor',
    images: [Image, Image, Image],
    Message: 'Generated text that mimics the structure of real writing.'
  },

  {
    id: 13,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 3,
    reviewedon: '04-11-2024',
    customername: 'Courtney Henry',
    images: [Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 14,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 4,
    reviewedon: '04-11-2024',
    customername: 'Jane Doe',
    images: [Image, Image, Image],
    Message: 'Filler text that shares some characteristics of a real written text but is random or otherwise generated.'
  },
  {
    id: 15,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 4.5,
    reviewedon: '04-11-2024',
    customername: 'John Smith',
    images: [Image, Image, Image],
    Message: 'Randomly generated text for demo purposes.'
  },
  {
    id: 16,
    profileimage: <FaCircleUser className='profileimage' />,
    rating: 3.5,
    reviewedon: '04-11-2024',
    customername: 'Sarah Connor',
    images: [Image, Image, Image],
    Message: 'Generated text that mimics the structure of real writing.'
  },
  // Add more testimonials as needed...
];

export const Testimonials = ({ testimonialheading = "Customer Testimonials", reviewdata = reviewData }) => {
  const [visibleCount, setVisibleCount] = useState(10);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showSlider, setShowSlider] = useState(false);

  // Function to load more testimonials
  const loadMoreTestimonials = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  // Function to handle image click for the slider
  const handleImageClick = (images) => {
    setSelectedImages(images);
    setShowSlider(true);  // Show the modal with the slider
  };
  

  return (
    <div className='Testimonialcards'>
      <h2>{testimonialheading}</h2>
      <div className='Testimonialcard1'>
        {reviewdata.slice(0, visibleCount).map((testimonialdata, index) => (
          <div className='Testimonialcard' key={index}>
            <div className='testimonialprofile'>
              {testimonialdata.profileimage}
              <div className='customernamerating'>
                <h4>{testimonialdata.customername}</h4>
                <p> <RatingStars rating={testimonialdata.rating} /> {testimonialdata.reviewedon}</p>
              </div>
            </div>
            <p>{testimonialdata.Message}</p>
            <ul className='testimonialimages'>
              {/* Show first 3 images */}
              {/* First 3 images */}
{testimonialdata.images.slice(0, 3).map((image, imgIndex) => (
  <li key={imgIndex}>
    <img
      src={image}
      alt={`Review ${testimonialdata.id} - item ${imgIndex + 1}`}
      className="testimonial-image"
      onClick={() => handleImageClick(testimonialdata.images)}
    />
  </li>
))}

{/* If there are more than 4 images, show the "+ more" on the 4th image */}
{testimonialdata.images.length > 4 ? (
  <li className="more-images">
    <div className="image-container">
      <img
        src={testimonialdata.images[3]} // 4th image
        alt={`Review ${testimonialdata.id} - additional`}
        className="testimonial-image"
        onClick={() => handleImageClick(testimonialdata.images)}
      />
      <div className="more-overlay" onClick={() => handleImageClick(testimonialdata.images)}>
        +{testimonialdata.images.length - 3} more
      </div>
    </div>
  </li>
) : (
  testimonialdata.images.length === 4 && (
    <li>
      <img
        src={testimonialdata.images[3]}
        alt={`Review ${testimonialdata.id} - item 4`}
        className="testimonial-image"
        onClick={() => handleImageClick(testimonialdata.images)}
      />
    </li>
  )
)}

            </ul>
          </div>
        ))}
      </div>

      {/* Load More button */}
      {visibleCount < reviewdata.length && (
        // <button className="load-more" onClick={loadMoreTestimonials}>
        //   Load More
        // </button>
        <Primarybutton  className="load-more" button='Load More' onClick={loadMoreTestimonials} />
      )}

      {/* Image Slider Modal */}
      {showSlider && (
        <Imageslider 
          images={selectedImages} 
          onClose={() => setShowSlider(false)} 
        />
      )}
    </div>
  );
};

export default Testimonials;