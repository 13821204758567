import React from 'react';
import './Backbutton.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import Buttonwithicon from '../Button/ButtonwithIcon';
import { IoMdArrowDropleft } from "react-icons/io";
export default function BackButton(){
    const navigate = useNavigate();  // Initialize useNavigate hook

    const handleBackClick = () => {
        navigate(-1);  // This will take the user to the previous page in history
    };

    return(
        <Buttonwithicon 
        icon={<IoMdArrowDropleft className='back-button-icon' />} 
        buttontext="Back to Itinerary" 
        onClick={handleBackClick} 
        className="back-button" />
    )
}