import React from 'react';
import Slider from 'react-slick';
import './items1.css';
import Mauritius from '../../Assets/Mauritius.webp';
import Malaysia from '../../Assets/Malaysia.jpg';
import Fiji from '../../Assets/Fiji.jfif';
import Thailand from '../../Assets/Thailand.jpg';
import Maldives from '../../Assets/Maldives.jpeg';
import Seychelles from '../../Assets/Seychelles.webp';
import Bhutan from '../../Assets/Bhutan.jpg';
import Kenya from '../../Assets/Kenya.jpg';
import Iran from '../../Assets/Iran.jfif';
import Grenada from '../../Assets/Grenada.jpg';
import Manali from '../../Assets/Manali.webp';
import Spitivalley from '../../Assets/Spitivalley.jpg';
import Ladakh from '../../Assets/Ladakh.jpeg';
import Shimla from '../../Assets/Shimla.jpg';
import Kasol from '../../Assets/Kasol.jpg';
import Kerala from '../../Assets/Kerala.jpg';
import Kashmir from '../../Assets/Kashmir.jpg';
import Andamannicobar from '../../Assets/Andamannicobar.jpg';
import Lakshadweep from '../../Assets/Lakshadweep.jpg';
import Himachalpradesh from '../../Assets/Himachalpradesh.jpg';

import { TiLocation } from "react-icons/ti";
import { Imagesettings } from '../Card1/Arrows';

export const destinations = [
  { name: 'Mauritius', price: '₹62,500', imageUrl: Mauritius, icon: <TiLocation className='tiLocation' /> },
  { name: 'Malaysia', price: '₹48,750', imageUrl: Malaysia, icon: <TiLocation className='tiLocation' /> },
  { name: 'Fiji', price: '₹111,338', imageUrl: Fiji, icon: <TiLocation className='tiLocation' /> },
  { name: 'Thailand', price: '₹37,500', imageUrl: Thailand, icon: <TiLocation className='tiLocation' /> },
  { name: 'Maldives', price: '₹43,854', imageUrl: Maldives, icon: <TiLocation className='tiLocation' /> },
  { name: 'Seychelles', price: '₹25,330', imageUrl: Seychelles, icon: <TiLocation className='tiLocation' /> },
  { name: 'Bhutan', price: '₹62,500', imageUrl: Bhutan, icon: <TiLocation className='tiLocation' /> },
  { name: 'Kenya', price: '₹48,750', imageUrl: Kenya, icon: <TiLocation className='tiLocation' /> },
  { name: 'Iran', price: '₹111,338', imageUrl: Iran, icon: <TiLocation className='tiLocation' /> },
  { name: 'Grenada', price: '₹37,500', imageUrl: Grenada, icon: <TiLocation className='tiLocation' /> }
];
export const domestic = [
  { name: 'Manali', price: '₹62,500', imageUrl: Manali, icon: <TiLocation className='tiLocation' /> },
  { name: 'Spiti Valley', price: '₹48,750', imageUrl: Spitivalley, icon: <TiLocation className='tiLocation' /> },
  { name: 'Ladakh', price: '₹111,338', imageUrl: Ladakh, icon: <TiLocation className='tiLocation' /> },
  { name: 'Shimla', price: '₹37,500', imageUrl: Shimla, icon: <TiLocation className='tiLocation' /> },
  { name: 'Kasol', price: '₹43,854', imageUrl: Kasol, icon: <TiLocation className='tiLocation' /> },
  { name: 'Kerala', price: '₹25,330', imageUrl: Kerala, icon: <TiLocation className='tiLocation' /> },
  { name: 'Kashmir', price: '₹62,500', imageUrl: Kashmir, icon: <TiLocation className='tiLocation' /> },
  { name: 'Himachal Pradesh', price: '₹48,750', imageUrl: Himachalpradesh, icon: <TiLocation className='tiLocation' /> },
  { name: 'Andaman Nicobar', price: '₹111,338', imageUrl: Andamannicobar, icon: <TiLocation className='tiLocation' /> },
  { name: 'Lakshadweep', price: '₹37,500', imageUrl: Lakshadweep, icon: <TiLocation className='tiLocation' /> },
];

const groupDestinations = (destinations) => {
  const grouped = [];
  for (let i = 0; i < destinations.length; i += 5) {
    grouped.push(destinations.slice(i, i + 5));
  }
  return grouped;
};


const Slider1 = ({heading,destinations}) => {
  
  const settingsForMultipleImages = Imagesettings(1); // Show 4 images at once

  const groupedDestinations = groupDestinations(destinations);

  return (
    <div className="app-container1">
      <h1 className='h1visa'>{heading}</h1>

<Slider {...settingsForMultipleImages} className='slider'>
        {groupedDestinations.map((group, index) => (
          <div className="multi-column-layout" key={index}>
            <div className="column1">
              {group.slice(0, 2).map((destination, idx) => (
                <DestinationCard
                  key={idx}
                  name={destination.name}
                  price={destination.price}
                  imageUrl={destination.imageUrl}
                  icon1={destination.icon}
                />
              ))}
            </div>
            <div className="column2 single-card">
              {group.slice(2, 3).map((destination, idx) => (
                <DestinationCard
                  key={idx}
                  name={destination.name}
                  price={destination.price}
                  imageUrl={destination.imageUrl}
                  icon1={destination.icon}
                />
              ))}
            </div>
            <div className="column3">
              {group.slice(3, 5).map((destination, idx) => (
                <DestinationCard
                  key={idx}
                  name={destination.name}
                  price={destination.price}
                  imageUrl={destination.imageUrl}
                  icon1={destination.icon}
                />
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

const DestinationCard = ({ name, price, imageUrl, icon1 }) => {
  return (
    <div className="card1">
      <div className="card-image1">
      <img src={imageUrl} alt={name} />
      <div className='text-overlay1'>
      <div className="destination-name1">{icon1}{name}</div>
      <div className="card-price1">From {price}</div>
      </div>
      </div>
    </div>
  );
};

export default Slider1;