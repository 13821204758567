// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listingtittledescription{
    background-color: white;
    color: #1e1e1e;
    margin: 20px 160px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    
}
.listingtittle{
    margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/Components/PackageListingtittledescription/Listingtittledescription.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,QAAQ;;AAEZ;AACA;IACI,WAAW;AACf","sourcesContent":[".listingtittledescription{\n    background-color: white;\n    color: #1e1e1e;\n    margin: 20px 160px;\n    padding: 20px;\n    border-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    \n}\n.listingtittle{\n    margin: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
