import BackButton from "../Components/BackButton/BackButton";
import Filterhotellisting from "../Components/Filterpackagelisting/FilterHotelListing";
import Navbar from "../Components/Navbar/Navbar1";

export default function HotelListingMain(){
    return(
        <div style={{backgroundColor:"#F7F8FB"}}>
            <Navbar />
            <BackButton />
            <Filterhotellisting />
        </div>
    )
}