// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Yas island.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-container {

    height: 300px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Optional: replace with your background image */
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    margin: 40px 160px;
    border-radius: 20px;
  }
  
  .banner-content {
    background: rgba(0, 0, 0, 0.5);
    width: 100%; /* Optional: for better text visibility */
    height: 100%;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

  }
  
  .h11 {
    font-size: 3em;
    margin: 0;
    font-family: 'amita'; /* Use a font similar to your design */
  }
  
  .banner-content p {
    font-size: 1.2em;
    margin: 10px 0;
    font-family: 'Amita';
  }
  
  .banner-button {
    background: linear-gradient(90deg, #ff5f6d, #ffc371);
    border: none;
    padding: 16px 34px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Tripbanner/Tripbanner.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,yDAAqD,EAAE,iDAAiD;IACxG,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,8BAA8B;IAC9B,WAAW,EAAE,yCAAyC;IACtD,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;;EAEX;;EAEA;IACE,cAAc;IACd,SAAS;IACT,oBAAoB,EAAE,sCAAsC;EAC9D;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,oBAAoB;EACtB;;EAEA;IACE,oDAAoD;IACpD,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB","sourcesContent":[".banner-container {\n\n    height: 300px;\n    background-image: url('../../Assets/Yas island.webp'); /* Optional: replace with your background image */\n    background-size: cover;\n    background-position: center;\n    color: white;\n    text-align: center;\n    margin: 40px 160px;\n    border-radius: 20px;\n  }\n  \n  .banner-content {\n    background: rgba(0, 0, 0, 0.5);\n    width: 100%; /* Optional: for better text visibility */\n    height: 100%;\n    padding: 20px;\n    border-radius: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n\n  }\n  \n  .h11 {\n    font-size: 3em;\n    margin: 0;\n    font-family: 'amita'; /* Use a font similar to your design */\n  }\n  \n  .banner-content p {\n    font-size: 1.2em;\n    margin: 10px 0;\n    font-family: 'Amita';\n  }\n  \n  .banner-button {\n    background: linear-gradient(90deg, #ff5f6d, #ffc371);\n    border: none;\n    padding: 16px 34px;\n    color: white;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 16px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
