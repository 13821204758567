import React, { useState, useEffect } from 'react';
import { packages } from '../Packagelisting/Packagelisting'; // Assuming you have a file exporting your packages data
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Textedbutton from '../Button/Textedbutton'; // Custom button component

export default function Activities() {
    const { id } = useParams(); // Get the package ID from the URL
    const navigate = useNavigate();
    const location = useLocation(); // Hook to retrieve passed state (updated activity)

    // Get the package data for the given ID
    const packageData = packages.find(pkg => pkg.id === parseInt(id));

    const [activities, setActivities] = useState(
        packageData ? packageData.Activities.map(activity => ({
            ...activity,
            manuallyAdded: activity.manuallyAdded || false, // Check if the activity is manually added
        })) : []
    );

    // UseEffect to check for updated activity when coming back from the selection page
    useEffect(() => {
        if (location.state && location.state.updatedActivity && location.state.activityToUpdateId) {
            const updatedActivity = location.state.updatedActivity;
            const activityToUpdateId = location.state.activityToUpdateId;

            // Update the activity with the new selected one
            setActivities(prevActivities => 
                prevActivities.map(activity => 
                    activity.Activityid === activityToUpdateId ? updatedActivity : activity
                )
            );
        }
    }, [location.state]); // Only depend on location.state

    const handleChange = (activityId) => {
        navigate(`/ActivityListing/${activityId}`, { state: { activityToUpdateId: activityId } });
    };

    const handleView = (activityId) => {
        navigate(`/ActivityDetails/${activityId}`);
    };

    const handleRemove = (activityId) => {
        const updatedActivities = activities.filter(activity => activity.Activityid !== activityId);
        setActivities(updatedActivities);
    };

    return (
        <div className="Activity-listing">
            {activities.length === 0 ? (
                <p className='Activity-content-information'>No activity data available.</p>
            ) : (
                activities.map((Activity) => (
                    <div key={Activity.Activityid} className='Activity-content-information'>
                        <div className='activity-content'>
                            <img src={Activity.Activityimage} alt={Activity.ActivityName} />
                            <div className='activity-details'>
                                <p className='Date'>{Activity.Date}</p>
                                <h3>{Activity.ActivityName}</h3>
                                <ul className='activity-time-type-amount'>
                                    <li>{Activity.time}</li>
                                    <li>{Activity.activitytype}</li>
                                    <li>{Activity.Activityamount}</li>
                                </ul>
                                <ul className="Inclusions">
                                    Inclusions:
                                    {Activity.Activityinclusions?.map((Inclusion, index) => (
                                        <li key={index}>{Inclusion}</li>
                                    ))}
                                </ul>
                                <ul className="Exclusions">
                                    Exclusions:
                                    {Activity.Activityexclusions?.map((Exclusion, index) => (
                                        <li key={index}>{Exclusion}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="insurance-actions">
                                <>
                                    <Textedbutton className="change-action" button='View' onClick={() => handleView(Activity.Activityid)} />
                                    <Textedbutton className="change-action" button='Change' onClick={() => handleChange(Activity.Activityid)} />
                                    {Activity.manuallyAdded && (
                                        <Textedbutton className="remove-action" button='Remove' onClick={() => handleRemove(Activity.Activityid)} />
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}
