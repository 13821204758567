import React from 'react';
import Textedbutton from '../Button/Textedbutton';
import Secondarybutton from '../Button/Secondary';
import { AddChangeRemove } from './AddChangeRemove';
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import { useParams } from "react-router-dom"; // Import the useParams hook

export default function Visa() {
  const { isAdded, handleAdd, handleRemove } = AddChangeRemove(); // Track if the visa is added
    const { id } = useParams();
    
    // Find the package by id
    const packageData = packages.find(pkg => pkg.id === parseInt(id));
  
    if (!packageData || !packageData.visa) {
      return <h1>Visa data not found</h1>;
    }

    const visa = packageData.visa; // Access the visa object directly

    // const handleAdd = () => {
    //     setIsAdded(true);
    // };
    
    // const handleRemove = () => {
    //     setIsAdded(false);
    // };

    return (
        <div className="Visa-content">
            <img 
              src={visa.image} 
              alt="Visa Plan" 
              className="insurance-image" 
            />
            {visa.included && (
          <div className="included-badge">
            <span>INCLUDED</span>
          </div>
        )}
    
            <div className="insurance-details">
                <h2 className="insurance-plan">{visa.tittle}</h2>
                <p>{visa.description}</p>
            </div>
            <div className="insurance-actions">
                {isAdded ? (
                    <Textedbutton button="Remove" className="remove-action" onClick={handleRemove} />
                ) : (
                    <Secondarybutton button="Add" className="add-button" onClick={handleAdd} />
                )}
            </div>
        </div>
    );
}
