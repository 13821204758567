import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landingpage from './Pages/Landingpage';
import Listing from './Pages/Listing';
import Packageitinerary from './Pages/Packageitinerary';
import HotelDetails from './Pages/HotelDetails';
import Hotel from './Components/Itinerarytabs/Hotel';
import Activities from './Components/Itinerarytabs/Activities';
import ActivityDetails from './Pages/ActivityDetails';
import ActivityListing from './Pages/ActivityListing';
import AllActivities from './Components/Activity/AllActivities';
import ItineraryTabs from './Components/Itinerarytabs/Itinerarytabs';
import HotelListing from './Components/Hotel/HotelListing';
import ItineraryTimeSlotsComponent from './Components/Itinerarytabs/ItineraryTimeSlots';
import HotelListingMain from './Pages/HotelListingMain';
import PrintItinerary from './Components/Itinerarytabs/ItineraryPrice/PrintItineraryPDF';
import PrintDownloadEmail from './Components/Itinerarytabs/ItineraryPrice/PrintDownloadEmail';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path='/' element={<Landingpage />} />
          <Route path='/Listing' element={<Listing />} />
          <Route path='/Packageitinerary/:id' element={<Packageitinerary />} />
          <Route path="/Activities/:id" element={<Activities />} />
          <Route path="/ActivityDetails/:activityId" element={<ActivityDetails />} />
          <Route path="/Hotel/:id" element={<Hotel />} />
          <Route path="/HotelDetails/:hotelId" element={<HotelDetails />} />
          <Route path="/ActivityListing/:activityId" element={<ActivityListing />} />
          <Route path='/allactivities/:packageId' element={<AllActivities />} />
          <Route path="/itinerary/:id" element={<ItineraryTabs />} />
          <Route path="/HotelListing" element={<HotelListing />} />
          <Route path="/AllHotelsListing" element={<HotelListingMain />} />
          <Route path="/itinerarytimeslot/:id" element={<ItineraryTimeSlotsComponent />} />
          <Route path="/PrintDownload" element={<PrintDownloadEmail />} />
          <Route path="/print-itinerary" element={<PrintItinerary />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
