import React from 'react';
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import { useParams } from "react-router-dom"; // Import the useParams hook

export default function Flights(){
    const { id } = useParams();
    // Find the package by id
  const packageData = packages.find(pkg => pkg.id === parseInt(id));

  if (!packageData) {
    return <h1>Activity data not found</h1>;
  }
    return(
        <div className='Flight-Details' key={packageData.id}>
          <ul className="Flight-Image">
                  {packageData.flights?.map((FlightImage, index) => (
                    <li key={index}> <img src={FlightImage} alt='Flight'></img></li>
                  ))}
                </ul>
        </div>
    )
}