// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    padding: 50px;
    border-radius: 0px 0px 10x 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: #f7f7f7;
    /* margin: 40px 160px; */
  }
  .header{
    font-style: 'manrope';
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .form{
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 10px;
  }
  .formGroup{
    display: flex;
    flex-direction: column;
    width: 25%;
    gap: 4px;
  }
  .label{
    margin-bottom: 5px;
  }
  .input{
    font-style: 'manrope';
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Forms/Formtravelnowpaylater.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;IAC/B,2CAA2C;IAC3C,yBAAyB;IACzB,wBAAwB;EAC1B;EACA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,SAAS;EACX;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,QAAQ;EACV;EACA;IACE,kBAAkB;EACpB;EACA;IACE,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,sBAAsB;EACxB","sourcesContent":[".container {\n    padding: 50px;\n    border-radius: 0px 0px 10x 10px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\n    background-color: #f7f7f7;\n    /* margin: 40px 160px; */\n  }\n  .header{\n    font-style: 'manrope';\n    text-align: center;\n    margin-top: 0px;\n    margin-bottom: 20px;\n  }\n  .form{\n    display: flex;\n    flex-direction: row;\n    align-items: end;\n    gap: 10px;\n  }\n  .formGroup{\n    display: flex;\n    flex-direction: column;\n    width: 25%;\n    gap: 4px;\n  }\n  .label{\n    margin-bottom: 5px;\n  }\n  .input{\n    font-style: 'manrope';\n    width: 100%;\n    height: 50px;\n    padding: 10px;\n    font-size: 14px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
