import React, {useState} from 'react';
import './Filterpackagelisting.css';
import Filter from '../Listingfilter/Filter';
import PackageListing from '../Packagelisting/Packagelisting';
import { packages } from '../Packagelisting/Packagelisting';

export default function Filterpackagelisting(){
    const [filters, setFilters] = useState({
        budget: [],
        noOfDays: [],
        rating: [],
      });
    const handleFilterChange = (filterValues) => {
        setFilters(filterValues);
      };
    return(
        <div className="packagefilter">
            <Filter  onFilterChange={handleFilterChange} />
            <PackageListing  packages={packages} filters={filters} />
        </div>
    );
};