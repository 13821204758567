import React from 'react';
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import { useParams } from "react-router-dom"; // Import the useParams hook
export default function Exclusion(){
    const { id } = useParams();
    // Find the package by id
  const packageData = packages.find(pkg => pkg.id === parseInt(id));

  if (!packageData) {
    return <h1>Exclusion data not found</h1>;
  }
    return(
        <div>
      <ul className="day-itinerary">
        {packageData.Exclusions.map((exclusion, index) => (
          <li className='exclusion' key={index}>{exclusion}</li>
        ))}
      </ul>
    </div>
    );
}