import React from "react";
import Navbar from "../Components/Navbar/Navbar1.jsx";
import './Listing.css';
import Footer from '../Components/Footer/Footer.jsx';
import PopularDestinations from "../Components/Card1/Items.jsx";
import { Unitedstates } from "../Components/Card1/Items.jsx";
import Listingtittledescription from "../Components/PackageListingtittledescription/Listingtittledescription.jsx";
import Packagetittle from "../Components/Packagetittle/Packagetittle.jsx";
import Packagelistingimages from "../Components/Packagelistingimages/Packagelistingimages.jsx";
import Packagedurationprice from "../Components/Packagedurationprice/Packagedurationprice.jsx";
import Testimonials from "../Components/ItemList/testimonials.jsx";
import { reviewData } from "../Components/ItemList/testimonials.jsx";
import Filterpackagelisting from "../Components/Filterpackagelisting/Filterpackagelisting.jsx";
import { packageslist } from "../Components/Packagelisting/Packages.js";

export default function Listing(){

    return(
        <div className="packagelisting" >
            <Navbar />
            <Packagetittle />
            {packageslist.map((packageItem) => (
            <Packagelistingimages packageImages={packageItem.Images} />
        ))}
            <Packagedurationprice />
            <Listingtittledescription />
            <Filterpackagelisting />
            <PopularDestinations heading="United States" destinations={Unitedstates} />
            <Testimonials testimonialheading='Travelers Love' reviewdata={reviewData} />
            <Footer />
        </div>
    );
}
