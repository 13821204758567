import React from 'react';
import './Tripstyle.css'; // Include external CSS for styling
import { FaPlane, FaMapMarkerAlt, FaCalendarAlt, FaDollarSign } from 'react-icons/fa';
import tripImage from '../../Assets/Background 1.jpg'; // Replace with your actual image path
import ButtonWithIcon from '../Button/Teritary';

const TripStyle = () => {
    const tripTypes = [
        { title: 'Couple', description: 'We provide the best travel services for you' },
        { title: 'Family', description: 'We provide the best travel services for you' },
        { title: 'Friends', description: 'We provide the best travel services for you' },
        { title: 'Solo', description: 'We provide the best travel services for you' }
    ];

    return (
        <div className="trip-style-container">
            <div  className="trip-style-heading">
            <h1>Choose Your <span>Trip Style</span></h1>
            </div>
            <div className="trip-style-content">
                {/* Left Side */}
                <div className="trip-options">
                    {tripTypes.slice(0, 2).map((type, index) => (
                        <div key={index} className="trip-option">
                            <h2>{type.title}</h2>
                            <p>{type.description}</p>
                            <ButtonWithIcon text="Details" iconPosition="right" />
                        </div>
                    ))}
                </div>

                {/* Image */}
                <div className="trip-image">
                    <img src={tripImage} alt="Trip Style" />
                        <table  className="trip-overlay">
                            <tbody className='space'>
                            <tr className='spacebetween'>
                                <th className="overlay-option"><FaPlane className='tripicon' /> Destinations</th>
                                <th className="overlay-option"><FaCalendarAlt className='tripicon' /> Date</th>
                            </tr>
                            <tr className='spacebetween'>
                                <th className="overlay-option"><FaMapMarkerAlt className='tripicon' /> Location</th>
                                <th className="overlay-option"><FaDollarSign className='tripicon' /> Prices</th>
                            </tr>
                            </tbody>
                        </table>
                        
                </div>

                {/* Right Side */}
                <div className="trip-options">
                    {tripTypes.slice(2).map((type, index) => (
                        <div key={index} className="trip-option">
                            <h2>{type.title}</h2>
                            <p>{type.description}</p>
                            <ButtonWithIcon text="Details" iconPosition="right" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TripStyle;
