import React from 'react';
import './DayWiseItinerary.css';
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import { useParams } from "react-router-dom"; // Import the useParams hook
import ItineraryTimeSlotsComponent from './ItineraryTimeSlots';
// import AddActivity from './AddActivity';

export default function DayWiseItinerary(){
    const { id } = useParams();
    const packageData = packages.find(pkg => pkg.id === parseInt(id));
    const renderIconWithDescription = (icon, description, altText) => {
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                {typeof icon === 'string' ? (
                    <img src={icon} alt={altText} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                ) : icon ? (
                    <span style={{ width: '20px', height: '20px', marginRight: '5px' }}>{icon}</span> // JSX component if it's not a string
                ) : null}
                {description}
            </div>
        );
    };
    return(
        <div className='dayWise-itinerary'>
            {packageData.Days.map((Day, index) => (
                <div className='days-itinerary' key={index}>
                    <p className="dayWise">Day {index + 1}</p>
                    <div className='box-line'>
                        <div className='ellipse' />
                        {/* Conditionally render vertical line, but hide it for the last index */}
                        {index !== packageData.Days.length - 1 && (
                            <div className='vertical-line' />
                        )}
                    </div> 
                    <div className="dayWise-activity">
                        <div className="activity-arrival-checkbox">
                            <h3 className="activity-arrival">{Day.tittle}</h3>
                        </div>
                        <div className="textfield_itinerary">
                        {Day.Morning && (
                                <ItineraryTimeSlotsComponent
                                    time="MORNING"
                                    flightdescription={renderIconWithDescription(packageData.Flighticon, Day.MorningFlightdescription, "Flight Icon")}
                                    Activitydescription={renderIconWithDescription(packageData.Activityicon, Day.MorningActivitydescription, "Activity Icon")}
                                    Transferdescription={renderIconWithDescription(packageData.Transfericon, Day.MorningTransferdescription, "Transfer Icon")}
                                    Hoteldescription={renderIconWithDescription(packageData.Hotelicon, Day.MorningHoteldescription, "Hotel Icon")}
                                    morningIndex={index}
                                />
                            )}
                    {Day.MorningAfternoon && (
                                <ItineraryTimeSlotsComponent
                                    time="MORNING & AFTERNOON"
                                    flightdescription={renderIconWithDescription(packageData.Flighticon, Day.MorningAfternoonFlightdescription, "Flight Icon")}
                                    Activitydescription={renderIconWithDescription(packageData.Activityicon, Day.MorningAfternoonActivitydescription, "Activity Icon")}
                                    Transferdescription={renderIconWithDescription(packageData.Transfericon, Day.MorningAfternoonTransferdescription, "Transfer Icon")}
                                    Hoteldescription={renderIconWithDescription(packageData.Hotelicon, Day.MorningAfternoonHoteldescription, "Hotel Icon")}
                                    morningAfternoonIndex={index}
                                />
                            )}
                        {Day.Afternoon && (
                                <ItineraryTimeSlotsComponent
                                    time="AFTERNOON"
                                    flightdescription={renderIconWithDescription(packageData.Flighticon, Day.AfternoonFlightdescription, "Flight Icon")}
                                    Activitydescription={renderIconWithDescription(packageData.Activityicon, Day.AfternoonActivitydescription, "Activity Icon")}
                                    Transferdescription={renderIconWithDescription(packageData.Transfericon, Day.AfternoonTransferdescription, "Transfer Icon")}
                                    Hoteldescription={renderIconWithDescription(packageData.Hotelicon, Day.AfternoonHoteldescription, "Hotel Icon")}
                                    afternoonIndex={index}
                                />
                            )}
                     {Day.Evening && (
                                <ItineraryTimeSlotsComponent
                                    time="EVENING"
                                    flightdescription={renderIconWithDescription(packageData.Flighticon, Day.EveningFlightdescription, "Flight Icon")}
                                    Activitydescription={renderIconWithDescription(packageData.Activityicon, Day.EveningActivitydescription, "Activity Icon")}
                                    Transferdescription={renderIconWithDescription(packageData.Transfericon, Day.EveningTransferdescription, "Transfer Icon")}
                                    Hoteldescription={renderIconWithDescription(packageData.Hotelicon, Day.EveningHoteldescription, "Hotel Icon")}
                                    eveningIndex={index}
                                />
                            )}
                    {Day.AfternoonEvening && (
                                <ItineraryTimeSlotsComponent
                                    time="AFTERNOON & EVENING"
                                    flightdescription={renderIconWithDescription(packageData.Flighticon, Day.AfternoonEveningFlightdescription, "Flight Icon")}
                                    Activitydescription={renderIconWithDescription(packageData.Activityicon, Day.AfternoonEveningActivitydescription, "Activity Icon")}
                                    Transferdescription={renderIconWithDescription(packageData.Transfericon, Day.AfternoonEveningTransferdescription, "Transfer Icon")}
                                    Hoteldescription={renderIconWithDescription(packageData.Hotelicon, Day.AfternoonEveningHoteldescription, "Hotel Icon")}
                                    afternooneveningIndex={index}
                                />
                            )}
                    {Day.Fullday && (
                                <ItineraryTimeSlotsComponent
                                    time="FULL DAY"
                                    flightdescription={renderIconWithDescription(packageData.Flighticon, Day.FulldayFlightdescription, "Flight Icon")}
                                    Activitydescription={renderIconWithDescription(packageData.Activityicon, Day.FulldayActivitydescription, "Activity Icon")}
                                    Transferdescription={renderIconWithDescription(packageData.Transfericon, Day.FulldayTransferdescription, "Transfer Icon")}
                                    Hoteldescription={renderIconWithDescription(packageData.Hotelicon, Day.FulldayHoteldescription, "Hotel Icon")}
                                    fulldayIndex={index}
                                />
                            )}

                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}