import { MegaMenu } from 'primereact/megamenu';
import {BasicDemo} from './data';
import {start}from './data';
import {EndComponent} from './data';
import { useScrollBackground } from './useScrollBackground';

import './flags.css';

export default function Navbar(){
    const navBackground = useScrollBackground(0); // Call the hook

    return(
        <div className='Nav1'>
            <MegaMenu className={`menu ${navBackground ? 'scrolled' : ''}`} model={BasicDemo} start={start} end={<EndComponent />} breakpoint="960px" />
        </div>
    )
}