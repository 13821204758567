import React from 'react';  
import './Footer.css'; 
import Expedia from '../../Assets/Expedia.svg';
import Skyscanner from '../../Assets/Skyscanner.svg';
import Turkish from '../../Assets/Turkish.svg';
import SunExpress from '../../Assets/SunExpress.svg';
  
// PartnerSection component  
const PartnerSection = () => {  
  return (
  <div className="partner-section">
    <h2>Partnered with the best in the industry</h2>
    <div className="logos">
        <img src={Expedia} alt="Expedia" />
        <img src={Skyscanner} alt="Skyscanner" />
        <img src={Turkish} alt="Turkish Airlines" />
        <img src={SunExpress} alt="SunExpress" />
        </div>
    </div>  
  );  
};  
  
// ThemePackagesSection component  
const ThemePackagesSection = ({ title, packages }) => {  
  return (
  <div className="theme-packages-section">
    <h3>{title}</h3>
    <ul>
        {packages.map((packageItem, index) => (
            <li key={index}>{packageItem}</li>
        ))}
    </ul>
    </div>  
  );  
};  
  
// FooterLinksSection component  
const FooterLinksSection = () => {  
  return (
    <div className="footer-links-section">
        <div className="column">
            <h4>Help center</h4>
            <ul>
                <li><a href=" ">FAQs</a></li>
                <li><a href=" ">Term of use</a></li>
                <li><a href=" ">Privacy Policy</a></li>
                <li><a href=" ">Cancellation policy</a></li>
                <li><a href=" ">Sitemap</a></li>
                <li><a href=" ">Contact us</a></li>
            </ul>
        </div>
        <div className="column">
            <h4>Company</h4>
            <ul>
                <li><a href=" ">About us</a></li>
                <li><a href=" ">Careers</a></li>
                <li><a href=" ">Blog</a></li>
                <li><a href=" ">Testimonial</a></li>
                <li><a href=" ">Visa</a></li>
            </ul>
        </div>
        <div className="column">
            <h4>Talk to us</h4>
            <ul>
                <li><a href=" ">Info@westerntravellers.com</a></li>
                <li><a href=" ">+91-8050041118</a></li>
            </ul>
        </div>
        <div className="column">
            <h4>Social</h4>
            <ul>
                <li><a href=" ">Facebook</a></li>
                <li><a href=" ">Instagram</a></li>
                <li><a href=" ">Pinterest</a></li>
                <li><a href=" ">Linkedin</a></li>
                <li><a href=" ">Twitter</a></li>
            </ul>
        </div>
    </div>  
  );  
};  
  
// Footer component  
const Footer = () => {  
  const packages = ['International Tour Packages',  
 'International Honeymoon Packages',  
 'International Family Packages', 
   'International Beach Packages', 
   'Adventure Packages',
   'Summer Holiday Packages',
   'International Visa on arrival Packages',
   'International Budget Packages',
   'International Luxury Packages',
   'International Solo Travel Packages'
  ];  
  
  return (
  <div className="footer">
    <PartnerSection />
    <hr />
    <div className="theme-packages">
        <ThemePackagesSection title="Themed destinations" packages={packages} />
        <hr />
        <ThemePackagesSection title="Holiday Destinations" packages={packages} />
        <hr />
        <ThemePackagesSection title="International Travel Deals" packages={packages} />
    </div>
    <hr />
    <FooterLinksSection />
    <hr />
    <div className="copyright">
        <p>&copy; 2023 Westerntravellers. All rights reserved.</p>
    </div>
</div>  
  );  
};  
  
export default Footer;
