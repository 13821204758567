// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-hotel-container{
    padding: 0px 160px;
}
.change-hotel{
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.change-hotel h3{
    margin: 0%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Activity/activityviewdetails.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;AACA;IACI,UAAU;AACd","sourcesContent":[".change-hotel-container{\n    padding: 0px 160px;\n}\n.change-hotel{\n    padding: 0px;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n}\n.change-hotel h3{\n    margin: 0%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
