// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.budget-section {
    padding: 40px 126px;
    text-align: center;
    background-color: #e0f7fa;
    border-radius: 10px;
    margin: 40px 160px;
  }
  
  .budget-heading {
    font-family: 'amita';
    font-size: 2rem;
    color: #4a4a4a;
  }
  
  .budget-heading .highlight {
    color: #ff9800;
  }
  
  .budget-box-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .budget-box {
    width: 100%;
    height: 120px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Manrope';
  }
  
  .budget-label {
    font-size: 1rem;
  }
  
  .budget-amount {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .budget-icon {
    margin-top: 10px;
    font-size: 1.2rem;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Budgetsection/Budgetsection.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,oBAAoB;IACpB,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;EACd","sourcesContent":[".budget-section {\n    padding: 40px 126px;\n    text-align: center;\n    background-color: #e0f7fa;\n    border-radius: 10px;\n    margin: 40px 160px;\n  }\n  \n  .budget-heading {\n    font-family: 'amita';\n    font-size: 2rem;\n    color: #4a4a4a;\n  }\n  \n  .budget-heading .highlight {\n    color: #ff9800;\n  }\n  \n  .budget-box-container {\n    display: flex;\n    justify-content: center;\n    gap: 20px;\n    margin-top: 20px;\n  }\n  \n  .budget-box {\n    width: 100%;\n    height: 120px;\n    border-radius: 12px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    font-family: 'Manrope';\n  }\n  \n  .budget-label {\n    font-size: 1rem;\n  }\n  \n  .budget-amount {\n    font-size: 1.4rem;\n    font-weight: bold;\n    margin-top: 5px;\n  }\n  \n  .budget-icon {\n    margin-top: 10px;\n    font-size: 1.2rem;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
