import React, { useState } from 'react';
import { packages } from '../Packagelisting/Packagelisting';
import { useParams, useNavigate } from "react-router-dom"; 
import Textedbutton from '../Button/Textedbutton';
import RatingStars from '../Rating/RatingStars';
import { IoIosInformationCircle } from "react-icons/io";
import HotelListing1 from '../Hotel/HotelListing1';
import BackfromListing from '../BackButton/BackfromListing';

// Modal component for full-screen or overlay behavior
const Modal = ({ children, onClose }) => (
  <div className="Hotel-modal-overlay">
    <div className="Hotel-modal-content">
      <BackfromListing onClick={onClose} />
      <div className="children-content">
      {children}
      </div>
    </div>
  </div>
);


export default function Hotel() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState(packages.find(pkg => pkg.id === parseInt(id)));
  const [changingHotelId, setChangingHotelId] = useState(null); // Track which hotel is being changed
  const [showModal, setShowModal] = useState(false);
  

  if (!packageData) {
    return <h1>Hotel data not found</h1>;
  }
  const handleChange = (hotelId) => {
    setChangingHotelId(hotelId);
    setShowModal(true); // Show the listing
  };
  const handleSelectHotel = (selectedHotel) => {
    const updatedHotels = packageData.Hotels.map(hotel => 
      hotel.Hotelid === changingHotelId ? selectedHotel : hotel
    );

    // Update the package data with the new hotels list
    setPackageData({
      ...packageData,
      Hotels: updatedHotels
    });
    setChangingHotelId(null); // Add new hotel to the list
    setShowModal(false); // Hide the listings when a hotel is selected
  };

  const handleView = (hotelId) => {
        navigate(`/HotelDetails/${hotelId}`);
      };



      return (
        <div className="Activity-listing">
          {showModal && (
            <Modal onClose={() => setShowModal(false)}>
              <HotelListing1 onSelectHotel={handleSelectHotel} />
            </Modal>
          )}
    
          {packageData.Hotels.map((Hotel) => (
            <div key={Hotel.Hotelid} className='Hotel-content-information'>
              <div className='Hotel-content'>
                <div className='Hotel-Image-Badge'>
                  <img src={Hotel.Hotelimage} alt={Hotel.Hotelname} />
                  {Hotel.Hotelexcluded && (
                    <div className="wt-recommended-badge">
                      <span>WT RECOMMENDED</span>
                    </div>
                  )}
                </div>
    
                <div className='activity-details'>
                  <p className='Date'>{Hotel.HotelDate}</p>
                  <h3>{Hotel.Hotelname}</h3>
                  <p className="Hotel-Rating">
                    <RatingStars rating={Hotel.Hotelrating} /> Trip Advisor
                  </p>
    
                  <ul className='activity-time-type-amount'>
                    <li>{Hotel.Hotelaccomodates}</li>
                    <li>{Hotel.Hotelamount}</li>
                    <li>{Hotel.Hotelarea}</li>
                    <li>{Hotel.Hoteltype}</li>
                  </ul>
    
                  <ul className="Inclusions">
                    Inclusions:
                    {Hotel.Hotelinclusions?.map((Inclusion, index) => (
                      <li key={index}>{Inclusion}</li>
                    ))}
                  </ul>
    
                  <ul className="Exclusions">
                    Exclusions:
                    {Hotel.Hotelexclusions?.map((Exclusion, index) => (
                      <li key={index}>{Exclusion}</li>
                    ))}
                  </ul>
                </div>
    
                <div className="insurance-actions">
                  <Textedbutton button="View" className="change-action" onClick={() => handleView(Hotel.Hotelid)} />
                  <Textedbutton button="Change" className="change-action" onClick={() => handleChange(Hotel.Hotelid)} />
                </div>
              </div>
              <hr />
              <div className="Hotel-price-info">
                <IoIosInformationCircle className='Hotel-info-icon' />
                <p>{Hotel.HotelInfo}</p>
              </div>
            </div>
          ))}
        </div>
      );
    }