import React from 'react';
import './Backbutton.css';
import Buttonwithicon from '../Button/ButtonwithIcon';
import { IoMdArrowDropleft } from "react-icons/io";
export default function BackfromListing({onClick}){
    return(
        <Buttonwithicon 
        icon={<IoMdArrowDropleft className='back-button-icon' />} 
        buttontext="Back to Itinerary" 
        onClick={onClick}
        className="back-button" />
    )
}