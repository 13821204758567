import React from 'react';
import '../App.js';
import Navbar1 from '../Components/Navbar/Navbar.jsx';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import PopularDestinations from '../Components/Card1/Items.jsx';
import { destinations1 } from '../Components/Card1/Items.jsx';
import { destinations2 } from '../Components/Card1/Items.jsx';
import { Europe } from '../Components/Card1/Items.jsx';
import { Oceania } from '../Components/Card1/Items.jsx';
import TripStyle from '../Components/Tripstyle/Tripstyle.jsx';
import BudgetSection from '../Components/Budgetsection/Budgetsection.jsx';
import Tripbanner from '../Components/Tripbanner/Tripbanner.jsx';
import Footer from '../Components/Footer/Footer.jsx';
import Slider1 from '../Components/Card2/Items1.jsx';
import { destinations } from '../Components/Card2/Items1.jsx';
import { domestic } from '../Components/Card2/Items1.jsx';
import { Middleeast } from '../Components/Card1/Items.jsx';
import Travelnowpaylater from '../Components/Banner/Travelnowpaylater.jsx';
import { Unitedstates } from '../Components/Card1/Items.jsx';

function Landingpage() {
  return (
    <div>
            <Navbar1 />
            <PopularDestinations heading="Popular Destinations" destinations={destinations1} />
            <PopularDestinations heading="Quick Getaways" destinations={destinations2} />
            <TripStyle />
            <Slider1 heading="Visa Free Destinations" destinations={destinations} />
            <Tripbanner />
            <PopularDestinations heading="Explore Europe" destinations={Europe} />
            <PopularDestinations heading="Oceania" destinations={Oceania} />
            <Slider1 heading="Explore Domestic Destinations" destinations={domestic} />
            <PopularDestinations heading="Middle East" destinations={Middleeast} />
            <BudgetSection />
            <PopularDestinations heading="United States" destinations={Unitedstates} />
            <Travelnowpaylater />
            <Footer />
        </div>
  );
}

export default Landingpage;
