// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  overflow: hidden; /* Prevent scrolling */
}

.modal-content {
  background-color: #fff;
  padding: 30px 40px 30px 40px;
  position: relative;
  width: 100%; /* Adjusted for responsiveness */
  max-width: 700px; /* Sets a max width */
  border-radius: 4px;
  align-items: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds slight shadow for better visibility */
}

.slick-slider {
  margin: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.slider-image-container {
  display: flex !important;
  justify-content: center;
  padding: 30px;
}

.slider-image {
  width: 100%;
  max-height: 350px; /* Ensures image doesn't overflow the modal */
  object-fit: cover; /* Maintain aspect ratio */
}
.modal-content .slick-prev{
  left: -20px;
}
.modal-content .slick-next{
  right: -20px;
}



`, "",{"version":3,"sources":["webpack://./src/Components/Imageslider/Imageslider.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,gBAAgB,EAAE,sBAAsB;AAC1C;;AAEA;EACE,sBAAsB;EACtB,4BAA4B;EAC5B,kBAAkB;EAClB,WAAW,EAAE,gCAAgC;EAC7C,gBAAgB,EAAE,qBAAqB;EACvC,kBAAkB;EAClB,mBAAmB;EACnB,2CAA2C,EAAE,6CAA6C;AAC5F;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,6BAA6B;EAC7B,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,iBAAiB,EAAE,6CAA6C;EAChE,iBAAiB,EAAE,0BAA0B;AAC/C;AACA;EACE,WAAW;AACb;AACA;EACE,YAAY;AACd","sourcesContent":["/* Modal styles */\n.modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 2000;\n  overflow: hidden; /* Prevent scrolling */\n}\n\n.modal-content {\n  background-color: #fff;\n  padding: 30px 40px 30px 40px;\n  position: relative;\n  width: 100%; /* Adjusted for responsiveness */\n  max-width: 700px; /* Sets a max width */\n  border-radius: 4px;\n  align-items: center;\n  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Adds slight shadow for better visibility */\n}\n\n.slick-slider {\n  margin: 0;\n}\n\n.close-button {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  background-color: transparent;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.slider-image-container {\n  display: flex !important;\n  justify-content: center;\n  padding: 30px;\n}\n\n.slider-image {\n  width: 100%;\n  max-height: 350px; /* Ensures image doesn't overflow the modal */\n  object-fit: cover; /* Maintain aspect ratio */\n}\n.modal-content .slick-prev{\n  left: -20px;\n}\n.modal-content .slick-next{\n  right: -20px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
