import React, { useState } from 'react';
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import { useParams } from "react-router-dom"; // Import the useParams hook
import Textedbutton from '../Button/Textedbutton';

export default function Transfer() {
    const { id } = useParams();

    // Find the package by id
    const packageData = packages.find(pkg => pkg.id === parseInt(id));
    
    // Local state to track the transfer type changes
    const [transfers, setTransfers] = useState(packageData.Transfer);

    if (!packageData) {
        return <h1>Activity data not found</h1>;
    }

    // Handle changing the transfer type to 'Public'
    const handleChangeToPublic = (transferId) => {
        setTransfers(prevTransfers =>
            prevTransfers.map(transfer =>
                transfer.Transferid === transferId
                    ? { ...transfer, Transfertype: 'Public' }
                    : transfer
            )
        );
    };

    // Handle changing the transfer type to 'Private'
    const handleChangeToPrivate = (transferId) => {
        setTransfers(prevTransfers =>
            prevTransfers.map(transfer =>
                transfer.Transferid === transferId
                    ? { ...transfer, Transfertype: 'Private' }
                    : transfer
            )
        );
    };

    return (
        <div className="Activity-listing">
            {transfers.map((transfer) => (
                <div key={transfer.Transferid} className='Activity-content-information'>
                    <div className='activity-content'>
                        <img src={transfer.Transferimage} alt={transfer.Transferfromto} />
                        <div className='activity-details'>
                            <p className='Date'>{transfer.TransferDate}</p>
                            <h3>{transfer.Transferfromto}</h3>
                            <ul className='activity-time-type-amount'>
                                <li>{transfer.Transfertype}</li>
                                <li>{transfer.Transfernoofpeople}</li>
                                <li>{transfer.Transfername}</li>
                            </ul>
                            <ul className="Transfer-Information">
                                {transfer.Transferinformation?.map((information, index) => (
                                    <li key={index}>{information}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="insurance-actions">
                            {/* Show button based on the transfer type */}
                            {transfer.Transfertype === 'Private' ? (
                                <Textedbutton
                                    button='Change to Public'
                                    className="change-action"
                                    onClick={() => handleChangeToPublic(transfer.Transferid)}
                                />
                            ) : transfer.Transfertype === 'Public' ? (
                                <Textedbutton
                                    button='Change to Private'
                                    className="change-action"
                                    onClick={() => handleChangeToPrivate(transfer.Transferid)}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
