import Formtravelnowpaylater from '../Forms/Formtravelnowpaylater';
import { LuBadgePercent } from "react-icons/lu";
import { GrDocumentText } from "react-icons/gr";
import { GrDocumentVerified } from "react-icons/gr";
import { GrDocumentTime } from "react-icons/gr";


import './travelnowpaylater.css';

export default function Travelnowpaylater(){
    return(
        <div className="banner1">
            <div className='Travelnowpaylaterimage'>
            <h5 className='wings'>Get your wings to fly with us</h5>
            <h1 className='h12'>Travel now pay later</h1>
            <table className='offers'>
                <tbody>
                    <tr className='offers1'>
                        <th className='offerslisting'><LuBadgePercent className='offersicon' /> No Cost EMI 6Months</th>
                        <th className='offerslisting'><GrDocumentTime className='offersicon' /> Hassle Free Process</th>
                        <th className='offerslisting'><GrDocumentText className='offersicon' /> Limited Documents</th>
                        <th className='offerslisting'><GrDocumentVerified className='offersicon' /> Instant Approval</th>
                    </tr>
                </tbody>
            </table>
            </div>
            <Formtravelnowpaylater /> 
        </div>
    );
}