import React from 'react';
import './ItineraryPrice.css'; // Import your CSS file for styling
import { packages } from '../../Packagelisting/Packagelisting';
import { Secondary } from '../../Button/Button';

const BookingSummary = () => {
  return (
    <div className="booking-summary-container">
      <div className="trip-details">
        <span className="trip-date">{packages.TravellingDate}.{packages.TravellingFrom}.{packages.NoofAdults}.{packages.NoofChildren}.{packages.Rooms}</span>
        <span className="edit-icon">✏️</span> {/* Replace with an actual icon if needed */}
      </div>

      <div className="price-section">
        <div className="total-price">
          <span>Total Price</span>
          <span className="price">₹ 126,034/-</span>
        </div>
        <div className="discounted-price">
          <span>₹ 36,034/-</span>
        </div>

        <hr />

        <div className="cost-breakdown">
          <div className="trip-cost">
            <span>Trip Cost :</span>
            <span>₹202,088/-</span>
          </div>
          <div className="tcs-cost">
            <span>TCS (5%) :</span>
            <span>₹10,105/-</span>
          </div>
          <hr />
          <div className="total-payable">
            <span>Total Payable Amount :</span>
            <span>₹212,193/-</span>
          </div>
        </div>
      </div>
      <Secondary buttontext="Book Now" className="book-now-btn" />
    </div>
  );
};

export default BookingSummary;
