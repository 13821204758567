import { useState } from 'react';
export const AddChangeRemove = () => {
 const [isAdded, setIsAdded] = useState(false); // Track if the visa is added
 
 const handleAdd = () => {
    setIsAdded(true);
};

 const handleRemove = () => {
    setIsAdded(false);
};
const handleChange = () => {
    // Handle change logic here
    console.log('Change action clicked');
  };
    return{ isAdded, handleAdd, handleRemove, handleChange }
}