import { MegaMenu } from 'primereact/megamenu';
import {BasicDemo} from './data';
import {start}from './data';
import {EndComponent} from './data';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { useScrollBackground } from './useScrollBackground';

import './flags.css';

export default function Navbar1(){

    const navBackground = useScrollBackground(0); // Call the hook


    
    return(
        <div className='Nav'>
            <MegaMenu 
            className={`menu ${navBackground ? 'scrolled' : ''}`} 
            model={BasicDemo} 
            start={start} 
            end={<EndComponent />} 
            breakpoint="960px" />
            <div className='banner'>
                <div className='bannertext'>
                    <h1 className='h1'>Let’s travel the world</h1>
                    <p className='paragraph'>Explore destinations, places, and unforgettable experiences</p>
                </div>
                <IconField iconPosition="right">
                    <InputText placeholder="Search destinations..." />
                    <InputIcon className="pi pi-search" />
                </IconField>
            </div>
        </div>
    )
}