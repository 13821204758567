// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating-stars {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  
  .rating-text {
    margin: 0px;
    font-size: 1rem;
    color: #333;
  }
  .Stars{
    display: flex;
    flex-direction: row;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Rating/RatingStar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,WAAW;EACb;EACA;IACE,aAAa;IACb,mBAAmB;EACrB","sourcesContent":[".rating-stars {\n    display: flex;\n    gap: 4px;\n    align-items: center;\n  }\n  \n  .rating-text {\n    margin: 0px;\n    font-size: 1rem;\n    color: #333;\n  }\n  .Stars{\n    display: flex;\n    flex-direction: row;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
