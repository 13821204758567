// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mapview-button {
    color: #50A9FF;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Mapview-button-icon{
    font-size: 20px;
}
.Mapview{
    display: flex;
    flex-direction: row;
}
.Mapview p{
    margin: 0%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Hotel/Hoteladdress.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;AACd","sourcesContent":[".Mapview-button {\n    color: #50A9FF;\n    font-size: 16px;\n    cursor: pointer;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.Mapview-button-icon{\n    font-size: 20px;\n}\n.Mapview{\n    display: flex;\n    flex-direction: row;\n}\n.Mapview p{\n    margin: 0%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
