import React from 'react';
import Slider from 'react-slick';
import './items.css'; // External CSS for custom styling
import Dubai from '../../Assets/Image 1.avif';
import Singapore from '../../Assets/Image 2.webp';
import Australia from '../../Assets/Image 3.avif';
import NewZealand from '../../Assets/Image 4.jfif';
import Azerbaijan from '../../Assets/Image 5.jpg';
import Turkey from '../../Assets/Image 7.webp';
import Bali from '../../Assets/Image 6.jpg';
import Maldives from '../../Assets/Image 8.jfif';
import Thailand from '../../Assets/Image 10.jpg';
import Vietnam from '../../Assets/Image 9.webp';
import Srilanka from '../../Assets/Image 11.webp';
import Switzerland from '../../Assets/Switzerland.avif';
import France from '../../Assets/France.jpg';
import Austria from '../../Assets/Austria.webp';
import Italy from '../../Assets/Italy.jpg';
import Spain from '../../Assets/Spain.webp';
import Finland from '../../Assets/Finland.webp';
import Norway from '../../Assets/Norway.webp';
import Turkey1 from '../../Assets/Turkey1.jpg';
import Unitedkingdom from '../../Assets/Unitedkingdom.jfif';
import Australia1 from '../../Assets/Australia1.jpg';
import Sydney from '../../Assets/Sydney.webp';
import Perth from '../../Assets/Perth.jpg';
import Melbourne from '../../Assets/Melbourne.jfif';
import Queensland from '../../Assets/Queensland.jpg';
import Fiji1 from '../../Assets/Fiji1.jpg';
import Newzealand from '../../Assets/Newzealand.webp';
import Dubai1 from '../../Assets/Dubai1.webp';
import Saudiarabia from '../../Assets/Saudiarabia.jpg';
import Aiuia from '../../Assets/Aiuia.jfif';
import Qatar from '../../Assets/Qatar.jpg';
import Bahrain from '../../Assets/Bahrain.jpg';
import Abudhabi from '../../Assets/Abudhabi.jpg';
import USA from '../../Assets/USA.jpg';
import Orlando from '../../Assets/Orlando.jpg';
import California from '../../Assets/California.avif';
import EastcoastUSA from '../../Assets/EastcoastUSA.jfif';
import WestcoastUSA from '../../Assets/WestcoastUSA.jpg';
import { TiLocation } from "react-icons/ti";
import { Imagesettings } from './Arrows';


export const destinations1 = [
  {
    name: 'Dubai',
    icon: <TiLocation className='tiLocation' />,
    image: Dubai,
    tagline: 'THE CITY OF LIFE',
  },
  {
    name: 'Singapore',
    icon: <TiLocation  className='tiLocation' />,
    image: Singapore,
    tagline: 'THE LION CITY',
  },
  {
    name: 'Australia',
    icon: <TiLocation  className='tiLocation' />,
    image: Australia,
    tagline: 'LAND OF DOWN UNDER',
  },
  {
    name: 'New Zealand',
    icon: <TiLocation  className='tiLocation' />,
    image: NewZealand,
    tagline: 'THE ADVENTURE CAPITAL',
  },
  {
    name: 'Azerbaijan',
    icon: <TiLocation  className='tiLocation' />,
    image: Azerbaijan,
    tagline: 'THE LAND OF FIRE',
  },
  {
    name: 'Turkey',
    icon: <TiLocation  className='tiLocation' />,
    image: Turkey,
    tagline: 'Magical Carpet Rides',
  }
];
export const destinations2 = [
  {
    name: 'Bali',
    icon: <TiLocation className='tiLocation' />,
    image: Bali,
    tagline: 'CULTURAL PARADISE',
  },
  {
    name: 'Maldives',
    icon: <TiLocation  className='tiLocation' />,
    image: Maldives,
    tagline: 'CREATE MEMORIES',
  },
  {
    name: 'Thailand',
    icon: <TiLocation  className='tiLocation' />,
    image: Thailand,
    tagline: 'The Kingdom',
  },
  {
    name: 'Vietnam',
    icon: <TiLocation  className='tiLocation' />,
    image: Vietnam,
    tagline: 'Land of Ascending Dragon',
  },
  {
    name: 'Srilanka',
    icon: <TiLocation  className='tiLocation' />,
    image: Srilanka,
    tagline: 'Fall in Love',
  }
];
export const Europe = [
  {
    name: 'Switzerland',
    icon: <TiLocation className='tiLocation' />,
    image: Switzerland,
    tagline: 'Alpine Views',
  },
  {
    name: 'France',
    icon: <TiLocation  className='tiLocation' />,
    image: France,
    tagline: 'The land of romance',
  },
  {
    name: 'Austria',
    icon: <TiLocation  className='tiLocation' />,
    image: Austria,
    tagline: 'Scenic Beauty',
  },
  {
    name: 'Italy',
    icon: <TiLocation  className='tiLocation' />,
    image: Italy,
    tagline: 'Roman Wonders',
  },
  {
    name: 'Spain',
    icon: <TiLocation  className='tiLocation' />,
    image: Spain,
    tagline: 'Historic Wonders',
  },
  {
    name: 'Finland',
    icon: <TiLocation  className='tiLocation' />,
    image: Finland,
    tagline: 'Stunning architectures',
  },
  {
    name: 'Norway',
    icon: <TiLocation  className='tiLocation' />,
    image: Norway,
    tagline: 'The Northern Lights',
  },
  {
    name: 'Turkey',
    icon: <TiLocation  className='tiLocation' />,
    image: Turkey1,
    tagline: 'Medical Carpet Rides',
  },
  {
    name: 'United Kingdom',
    icon: <TiLocation  className='tiLocation' />,
    image: Unitedkingdom,
    tagline: 'Embroiled in history',
  }
];
export const Oceania = [
  {
    name: 'Australia',
    icon: <TiLocation className='tiLocation' />,
    image: Australia1,
    tagline: 'Land of down',
  },
  {
    name: 'New Zealand',
    icon: <TiLocation  className='tiLocation' />,
    image: Newzealand,
    tagline: 'The adventure capital',
  },
  {
    name: 'Fiji',
    icon: <TiLocation  className='tiLocation' />,
    image: Fiji1,
    tagline: 'The Pristine Waters',
  },
  {
    name: 'Queensland',
    icon: <TiLocation  className='tiLocation' />,
    image: Queensland,
    tagline: 'Sunshine state under',
  },
  {
    name: 'Melbourne',
    icon: <TiLocation  className='tiLocation' />,
    image: Melbourne,
    tagline: 'Australias sport capital',
  },
  {
    name: 'Perth',
    icon: <TiLocation  className='tiLocation' />,
    image: Perth,
    tagline: 'Gateway to western aussie',
  },
  {
    name: 'Sydney',
    icon: <TiLocation  className='tiLocation' />,
    image: Sydney,
    tagline: 'Australias harbour city',
  }
];
export const Middleeast = [
  {
    name: 'Dubai',
    icon: <TiLocation className='tiLocation' />,
    image: Dubai1,
    tagline: 'The city of life',
  },
  {
    name: 'Abu Dhabi',
    icon: <TiLocation  className='tiLocation' />,
    image: Abudhabi,
    tagline: 'A step back in time',
  },
  {
    name: 'Bahrain',
    icon: <TiLocation  className='tiLocation' />,
    image: Bahrain,
    tagline: 'The Pristine Waters',
  },
  {
    name: 'Qatar',
    icon: <TiLocation  className='tiLocation' />,
    image: Qatar,
    tagline: 'The land of luxury',
  },
  {
    name: 'Aiuia',
    icon: <TiLocation  className='tiLocation' />,
    image: Aiuia,
    tagline: 'The old wonders',
  },
  {
    name: 'Saudi Arabia',
    icon: <TiLocation  className='tiLocation' />,
    image: Saudiarabia,
    tagline: 'Kingdom that Inspires',
  }
];
export const Unitedstates = [
  {
    name: 'USA',
    icon: <TiLocation className='tiLocation' />,
    image: USA,
    tagline: 'Discover the best of America',
  },
  {
    name: 'Orlando',
    icon: <TiLocation  className='tiLocation' />,
    image: Orlando,
    tagline: 'World theme park capital',
  },
  {
    name: 'California',
    icon: <TiLocation  className='tiLocation' />,
    image: California,
    tagline: 'Land of hollywood',
  },
  {
    name: 'East Coast USA',
    icon: <TiLocation  className='tiLocation' />,
    image: EastcoastUSA,
    tagline: 'Explore along the atlantic',
  },
  {
    name: 'West Coast USA',
    icon: <TiLocation  className='tiLocation' />,
    image: WestcoastUSA,
    tagline: 'Explore along the pacific',
  }
];

const PopularDestinations = ({heading,destinations}) => {

  const settingsForMultipleImages = Imagesettings(4); // Show 4 images at once



  return (
      <div className="popular-destinations">
      <h2 className="section-title">{heading}</h2>
      <Slider {...settingsForMultipleImages}>
        {destinations.map((destination, index) => (
          <div className="destination-card" key={index}>
            <img src={destination.image} alt={destination.name} />
            <div className='text-overlay'>
            <div className="destination-info">
              {destination.icon}{destination.name}
            </div>
            <div className='text-line'>{destination.tagline}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};





export default PopularDestinations;
