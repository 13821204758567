import React from 'react';
import Primarybutton from '../Button/Primary';
import './Tripbanner.css'; // Create this CSS file for styling or use inline styles

const Tripbanner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1 className='h11'>Say yes for a trip to Yas Island</h1>
        <p>Kids go free on Yas Island ABU DHABI</p>
        <Primarybutton button='View Packages' />
      </div>
    </div>
  );
};

export default Tripbanner;
