import React from 'react';
import Textedbutton from '../Button/Textedbutton';
import { IoIosInformationCircle } from "react-icons/io";
import Secondarybutton from '../Button/Secondary';
import { AddChangeRemove } from './AddChangeRemove';
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import { useParams } from "react-router-dom"; // Import the useParams hook

export default function Insurance() {
  const { isAdded, handleAdd, handleRemove, handleChange } = AddChangeRemove(); // Track if the visa is added
    const { id } = useParams();
    
    // Find the package by id
    const packageData = packages.find(pkg => pkg.id === parseInt(id));
  
    if (!packageData || !packageData.Insurance) {
      return <h1>Visa data not found</h1>;
    }

    const Insurance = packageData.Insurance; // Access the visa object directly

    return (
        <div className="day-itinerary">
            <div className="insurance-content">
        <img 
          src={Insurance.image} 
          alt="Insurance Plan" 
          className="insurance-image" 
        />
        {Insurance.excluded && (
          <div className="excluded-badge">
            <span>EXCLUDED</span>
          </div>
        )}

        <div className="insurance-details">
          <h4 className="insurance-tittle">{Insurance.title}</h4>
          <h2 className="insurance-plan">{Insurance.plan}</h2>
          <p>{Insurance.description}</p>
        </div>
        <div className="insurance-actions">
        {isAdded ? (
                <>
                  <Textedbutton button='Change' className="change-action" onClick={handleChange} />
                  <Textedbutton button='Remove' className="remove-action" onClick={handleRemove} />
                </>
              ) : (
                <Secondarybutton button='Add'  className="add-button" onClick={handleAdd} />
              )}
        </div>
      </div>
      <hr />

      <div className="insurance-price-info">
      <IoIosInformationCircle className='insurance-info-icon' />
        <p>{Insurance.priceInfo}</p>
      </div>
        </div>
    );
}
