import React from 'react';
import { useParams } from "react-router-dom"; // Import the useParams hook
import BackButton from "../Components/BackButton/BackButton";
import Navbar from "../Components/Navbar/Navbar1";
import Hotelviewdetails from '../Components/Hotel/Hotelviewdetails';
import Packagelistingimages from '../Components/Packagelistingimages/Packagelistingimages';
import { packages } from "../Components/Packagelisting/Packagelisting";
import HotelTabDetails from '../Components/Hotel/HotelTabDetails';




export default function HotelDetails(){
    const { hotelId } = useParams(); // Get the hotel ID from the URL

  // Find the correct hotel using hotelId from the packages array
  const hotelData = packages
    .flatMap(pkg => pkg.Hotels || [])  // Flatten the Hotels from each package
    .find(hotel => hotel.Hotelid === hotelId); // Find the hotel with the matching ID

  if (!hotelData) {
    return <h1>Hotel not found</h1>; // Show an error if no matching hotel is found
  }
    return(
        <div>
            <Navbar />
            <BackButton />
            <Hotelviewdetails hotelId={hotelId} />
            <Packagelistingimages packageImages={hotelData.HotelheaderImages} />
            <HotelTabDetails />
        </div>
    )
}