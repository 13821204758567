// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.packagedurationpricerating{
    background-color: white;
    padding: 10px 160px;
    display: flex;
    flex-direction: row;
}
.packagedurationprice{
    width: 33.3%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    object-fit: cover;
}
.Ratingstars{
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.packagedurationprice h4{
    margin: 0px;
}
.packagedurationprice p{
    margin: 0px;
    color: #888888;
}
.packageexpertise{
    width: 33.3%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    object-fit: cover;
    align-items: end;
}
.packageexpertise h4{
    margin: 0px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: center;
}
.packageexpertise p{
    margin: 0px;
    color: #888888;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Packagedurationprice/packagedurationprice.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,cAAc;AAClB;AACA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,cAAc;AAClB","sourcesContent":[".packagedurationpricerating{\n    background-color: white;\n    padding: 10px 160px;\n    display: flex;\n    flex-direction: row;\n}\n.packagedurationprice{\n    width: 33.3%;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n    object-fit: cover;\n}\n.Ratingstars{\n    display: flex;\n    flex-direction: row;\n    gap: 4px;\n}\n.packagedurationprice h4{\n    margin: 0px;\n}\n.packagedurationprice p{\n    margin: 0px;\n    color: #888888;\n}\n.packageexpertise{\n    width: 33.3%;\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n    object-fit: cover;\n    align-items: end;\n}\n.packageexpertise h4{\n    margin: 0px;\n    display: flex;\n    flex-direction: row;\n    gap: 6px;\n    justify-content: center;\n}\n.packageexpertise p{\n    margin: 0px;\n    color: #888888;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
