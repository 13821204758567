// src/PrintItinerary.jsx
import React from 'react';

const PrintItinerary = () => {
  return (
    <div>
      <h1>Your Itinerary</h1>
      {/* Add your itinerary content here */}
      <p>This is where the details of your itinerary will be displayed for printing.</p>
    </div>
  );
};

export default PrintItinerary;
