import React from 'react';
import { useNavigate } from "react-router-dom";
import RatingStars from '../Rating/RatingStars.jsx';
import { Secondary } from '../Button/Button.jsx';

export default function HotelListing({ hotelData = [], filters = {} }) {
    const navigate = useNavigate();

     // Provide default values for filters to avoid undefined errors
     const {
        timeslot = [],
        transfer = [],
        groupType = [],
        interests = [],
        otherFilters = [],
    } = filters;

    const handleSelectHotel = (selectedHotel) => {
        // Log the selected hotel to ensure data is being passed correctly
        console.log("Hotel selected for update:", selectedHotel);
    
        // Pass the hotel data with navigate, using the correct state structure
        navigate(-1, { state: { updatedHotel: selectedHotel, hotelToUpdateId: selectedHotel.Hotelid } });
    };

    // Filter activities based on the provided filters
    const applyFilters = (activity) => {
        if (timeslot.length > 0 && !timeslot.includes(activity.timeSlot)) return false;
        if (transfer.length > 0 && !transfer.includes(activity.transferIncluded ? 'Included' : 'Not Included')) return false;
        if (groupType.length > 0 && !groupType.includes(activity.groupType)) return false;
        if (interests.length > 0 && !activity.interests.some(interest => interests.includes(interest))) return false;
        if (otherFilters.length > 0 && !otherFilters.every(filter => activity.filters.includes(filter))) return false;
        return true;
    };
    

    return (
        <div className="Activity-listing">
            {hotelData.filter(applyFilters).map((hotel) => ( 
                <div key={hotel.Hotelid} className='Hotel-content-information'>
                    <div className='Hotel-content'>
                        <div className='Hotel-Image-Badge'>
                            <img src={hotel.Hotelimage} alt={hotel.Hotelname} />
                            {hotel.Hotelexcluded && (
                                <div className="wt-recommended-badge">
                                    <span>WT RECOMMENDED</span>
                                </div>
                            )}
                        </div>
                        <div className='activity-details'>
                            <p className='Date'>{hotel.HotelDate}</p>
                            <h3>{hotel.Hotelname}</h3>
                            <p className="Hotel-Rating">
                                <RatingStars rating={hotel.Hotelrating} /> Trip Advisor
                            </p>

                            <ul className='activity-time-type-amount'>
                                <li>{hotel.Hotelaccomodates}</li>
                                <li>{hotel.Hotelamount}</li>
                                <li>{hotel.Hotelarea}</li>
                                <li>{hotel.Hoteltype}</li>
                            </ul>

                            <ul className="Inclusions">
                                Inclusions:
                                {hotel.Hotelinclusions?.map((Inclusion, index) => (
                                    <li key={index}>{Inclusion}</li>
                                ))}
                            </ul>

                            <ul className="Exclusions">
                                Exclusions:
                                {hotel.Hotelexclusions?.map((Exclusion, index) => (
                                    <li key={index}>{Exclusion}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="insurance-actions">
                            <Secondary buttontext="Select" onClick={() => handleSelectHotel(hotel)} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
