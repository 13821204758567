import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation } from "react-router-dom";
import './Itinerarytabs.css'; // Custom CSS
import { FaCalendarAlt } from "react-icons/fa";
import { FaCarSide } from "react-icons/fa6";
import { MdFlight } from "react-icons/md";
import { RiHotelFill } from "react-icons/ri";
import { TbTrekking } from "react-icons/tb";
import { FaCcVisa } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import { packages } from '../Packagelisting/Packagelisting'; // Import the packages data
import Activities from './Activities';
import Flights from './Flights';
import Transfer from './Transfer';
import Hotel from './Hotel';
import Inclusion from './Inclusion';
import Exclusion from './Exclusion';
import Visa from './Visa';
import Insurance from './Insurance';
import PrintDownloadEmail from './ItineraryPrice/PrintDownloadEmail.jsx';
import Packagepolicies from './ItineraryPrice/Packagepolicies.jsx';
import ChatExpertCard from './ItineraryPrice/TravelExpert.jsx';
import BookingSummary from './ItineraryPrice/BookingSummary.jsx';
import DaybydayTabs from './DaybydayTabs.jsx';
import DayWiseItinerary from './DayWiseItinerary.jsx';

const ItineraryTabs = () => {
    const dayByDayRef = useRef(null);
    const flightsRef = useRef(null);
    const transferRef = useRef(null);
    const hotelRef = useRef(null);
    const actvitiesRef = useRef(null);
    const visaRef = useRef(null);
    const insuranceRef = useRef(null);
    const inclusionRef = useRef(null);
    const exclusionRef = useRef(null);

    const [activeTab, setActiveTab] = useState('dayByDay');
    const { id } = useParams();
    
    const [packageData, setPackageData] = useState(() => {
      // Initial state setup, assuming packages is the original data source
      return packages.find(pkg => pkg.id === parseInt(id));
  });
  const location = useLocation();

  // Check for hotel update
  useEffect(() => {
      if (location.state && location.state.updatedHotel && location.state.hotelToUpdateId) {
          const updatedHotel = location.state.updatedHotel;
          const hotelToUpdateId = location.state.hotelToUpdateId;

          // Update the hotel data in packageData state
          setPackageData(prevPackageData => {
            return {
                ...prevPackageData,
                Hotels: prevPackageData.Hotels.map(h =>
                    h.id === hotelToUpdateId ? updatedHotel : h // Here hotel.id is compared to hotelToUpdateId
                )
            };
        });
      }
  }, [location.state]);

    // Function to scroll to the specific section and set active tab
    const handleScroll = (ref, tab) => {
        const topOffset = 200; // Adjust this offset based on the height of your sticky header
        const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: elementPosition - topOffset,
            behavior: 'smooth',
        });
        setActiveTab(tab); // Set the clicked tab as active
    };

    // Function to detect scroll and highlight the tab accordingly
    const handleScrollHighlight = () => {
        const sections = [
            { ref: dayByDayRef, name: 'dayByDay' },
            { ref: flightsRef, name: 'flights' },
            { ref: transferRef, name: 'transfer' },
            { ref: hotelRef, name: 'hotel' },
            { ref: actvitiesRef, name: 'activity' },
            { ref: visaRef, name: 'visa' },
            { ref: insuranceRef, name: 'insurance' },
            { ref: inclusionRef, name: 'inclusion' },
            { ref: exclusionRef, name: 'exclusion' },
        ];

        // Loop through sections and check if they're visible in the viewport
        for (let i = 0; i < sections.length; i++) {
            const sectionTop = sections[i].ref.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;

            // If the section is at least 50% visible in the viewport
            if (sectionTop >= 0 && sectionTop <= windowHeight * 0.5) {
                setActiveTab(sections[i].name);
                break;
            }
        }
    };

    // Add scroll event listener
    useEffect(() => {
        window.addEventListener('scroll', handleScrollHighlight);
        return () => {
            window.removeEventListener('scroll', handleScrollHighlight);
        };
    }, []);

    return (
        <div className="itinerary-container">
            {/* Sticky Tab Menu */}
            <div className="sticky-itinerary-tabs">
                <div className="tab-menu">
                    <button onClick={() => handleScroll(dayByDayRef, 'dayByDay')} className={activeTab === 'dayByDay' ? 'active-tab' : ''}>
                        Day by Day
                    </button>
                    <button onClick={() => handleScroll(flightsRef, 'flights')} className={activeTab === 'flights' ? 'active-tab' : ''}>
                        Flights
                    </button>
                    <button onClick={() => handleScroll(transferRef, 'transfer')} className={activeTab === 'transfer' ? 'active-tab' : ''}>
                        Transfer
                    </button>
                    <button onClick={() => handleScroll(hotelRef, 'hotel')} className={activeTab === 'hotel' ? 'active-tab' : ''}>
                        Hotel
                    </button>
                    <button onClick={() => handleScroll(actvitiesRef, 'activity')} className={activeTab === 'activity' ? 'active-tab' : ''}>
                        Activity
                    </button>
                    <button onClick={() => handleScroll(visaRef, 'visa')} className={activeTab === 'visa' ? 'active-tab' : ''}>
                        Visa
                    </button>
                    <button onClick={() => handleScroll(insuranceRef, 'insurance')} className={activeTab === 'insurance' ? 'active-tab' : ''}>
                        Insurance
                    </button>
                    <button onClick={() => handleScroll(inclusionRef, 'inclusion')} className={activeTab === 'inclusion' ? 'active-tab' : ''}>
                        Inclusions
                    </button>
                    <button onClick={() => handleScroll(exclusionRef, 'exclusion')} className={activeTab === 'exclusion' ? 'active-tab' : ''}>
                        Exclusions
                    </button>
                </div>
            </div>
            <div className='tab-content-pricing'>
                {/* Tab Content */}
                <div className="tab-content">
                    {/* Day by Day Section */}
                    <div ref={dayByDayRef} className="section">
                        <h2><FaCalendarAlt /> Day by Day Itinerary</h2>
                        <div className="day-itinerary">
                            <DayWiseItinerary />
                        </div>
                        <div>
                            <DaybydayTabs />
                        </div>
                    </div>

                    {/* Flights Section */}
                    <div ref={flightsRef} className="section">
                        <h2><MdFlight /> Flight Details</h2>
                        <Flights />
                    </div>

                    {/* Transfer Section */}
                    <div ref={transferRef} className="section">
                        <h2><FaCarSide /> Transfer Information</h2>
                        <Transfer />
                    </div>

                    {/* Hotel Section */}
                    <div ref={hotelRef} className="section">
                        <h2><RiHotelFill /> Hotel Information</h2>
                        <Hotel hotelData={packageData.Hotels} />
                    </div>

                    {/* Activities Section */}
                    <div ref={actvitiesRef} className="section">
                        <h2><TbTrekking /> Activities Information</h2>
                        <Activities />
                    </div>

                    {/* Visa Section */}
                    <div ref={visaRef} className="section">
                        <h2><FaCcVisa /> Visa Information</h2>
                        <Visa />
                    </div>

                    {/* Insurance Section */}
                    <div ref={insuranceRef} className="section">
                        <h2><FaAddressCard /> Insurance Information</h2>
                        <Insurance />
                    </div>

                    {/* Inclusions Section */}
                    <div ref={inclusionRef} className="section">
                        <h2><IoMdCheckmark /> Inclusions Information</h2>
                        <Inclusion />
                    </div>

                    {/* Exclusions Section */}
                    <div ref={exclusionRef} className="section">
                        <h2><IoCloseSharp /> Exclusions Information</h2>
                        <Exclusion />
                    </div>
                </div>
                <div className='itinerary-price'>
                    <BookingSummary />
                    <ChatExpertCard />
                    <Packagepolicies />
                    <PrintDownloadEmail />
                </div>
            </div>
        </div>
    );
};

export default ItineraryTabs;
